import Link from 'next/link';
import React from 'react'
import Slider from 'react-slick';
import config from '../../../config';
function latestTrending(props) {
    // console.log("98989", props)
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '50px',
        dots: false,
        arrows: false,
        
      };
      return (
        <Slider {...settings}>
          <div>
            <div className='new-collection-bx' id='latest-trending-1'>
            <a href={`${config.HEADLESS_BASE_URL}/tiles/anti-skid-tiles`}>
                <img src="/ANTI SKID TILES.jpg"  alt='ANTI SKID TILES'/>
                <h6>ANTI SKID TILES</h6>
                </a>
            </div>
          </div>
          <div>
          < div className='new-collection-bx' id='latest-trending-2'>
          <a href={`${config.HEADLESS_BASE_URL}/tiles/cool-tiles`}>
                <img src="COOL ROOF TILES.jpg"  alt='COOL ROOF TILES'/>
                <h6>COOL ROOF TILES</h6>
                </a>
            </div>
            
          </div>


          <div>
          < div className='new-collection-bx' id='latest-trending-3'>
          <a href={`${config.HEADLESS_BASE_URL}/tiles/elevation-tiles`}>
                <img src="ELEVATION TILES.jpg"  alt='ELEVATION TILES'/>
                <h6>ELEVATION TILES</h6>
                </a>
            </div>
            
          </div>

          {/* <div>
          < div className='new-collection-bx'>
                <img src="HEAVY LOAD TILES.jpg"  alt='HEAVY LOAD TILES'/>
                <h6><a href="#">HEAVY LOAD TILES</a></h6>
            </div>
            
          </div> */}

          <div>
          < div className='new-collection-bx' id='latest-trending-4'>
          <a href={`${config.HEADLESS_BASE_URL}/tiles/highlighter-tiles`}>
                <img src="/HIGHLIGHTER TILES.jpg"  alt='HIGHLIGHTER TILES'/>
                <h6>HIGHLIGHTER TILES</h6></a>
            </div>
            
          </div>

          <div>
          < div className='new-collection-bx' id='latest-trending-5'>
          <a href={`${config.HEADLESS_BASE_URL}/tiles/forever-tiles`}>
                <img src="/SCRATCH FREE TILES.jpg"  alt='SCRATCH FREE TILES'/>
                <h6>SCRATCH FREE TILES(FOREVER TILES)</h6></a>
            </div>
            
          </div>
          <div>
          < div className='new-collection-bx' id='latest-trending-6'>
          <a href={`${config.HEADLESS_BASE_URL}/tiles/large-tiles`}>
                <img src="/LARGE SLAB TILES.jpg"  alt='LARGE SLAB TILES'/>
                <h6>LARGE SLAB TILES</h6></a>
            </div>
            
          </div>
          <div>
          < div className='new-collection-bx' id='latest-trending-7'>
          <a href={`${config.HEADLESS_BASE_URL}/tiles/parking-tiles`}>
                <img src="/PARKING TILES.jpg"  alt='PARKING TILES'/>
                <h6>PARKING TILES</h6></a>
            </div>
            
          </div>

          <div>
          < div className='new-collection-bx' id='latest-trending-8'>
          <a href={`${config.HEADLESS_BASE_URL}/tiles/wall-tiles`}>
                <img src="/WALL TILES1.jpg"  alt='WALL TILES'/>
                <h6>WALL TILES</h6></a>
            </div>
            
          </div>


          < div className='new-collection-bx' id='latest-trending-9'>
               <Link href={`${config.HEADLESS_BASE_URL}/tiles`}> <img src="/view-all-tiles-black.jpg"  alt='view all tiles image'/></Link>
             
            </div>
        </Slider>
      );
}

export default latestTrending